import '../assets/css/profileedit.css';

import Formsy from 'formsy-react';
import { navigate, Link } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';

import { setCredentials } from './../core/auth';
import ButtonLoader from '../components/buttonLoader';
import ErrrorHandel from '../components/ErrrorHandel';
import Layout from '../components/layout';
import closeChat from '../images/close-icon.svg';
import MyInput from '../components/MyInput';
import SEO from '../components/seo';
import { isAuthenticated,getAuthenticatedUser } from '../core/util';
import * as actionCreators from './../store/actions';

class ProfileEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            userToken:null,
            successMsg: '',
        };
    }

    async componentDidMount() {
        console.log('global.dealertoken::::::::::::::'+global.dealertoken)
        if (!isAuthenticated()) {
            navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        } else {
            let usrdata = getAuthenticatedUser();
            this.setState({ userToken: usrdata.token })
            await this.getFromDirect(usrdata.token);
        }
        
    }

    getFromDirect = async(paramId) => {
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getProfile'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: paramId,
                })
              })
            .then(res => res.json())
            .then((res) => {
                if(res.status === 0){
                    setCredentials(null);
                    navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                }
            });
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = (model) => {
        const { showButtonLoader, hideButtonLoader } = this.props;
        showButtonLoader()
        model.token = this.state.userToken;
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/changePassword'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
          fetch(uri,{
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(model)
            })
            .then(res => res.json())
            .then((res) => {
                if(res.status === -1){
                    setCredentials({});
                    navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                } else if(res.status === 0){
                    this.setState({ successMsg: 'Incorrect Password' })
                } else if(res.status === 1){
                    this.setState({ successMsg: 'Password Change successfully' })
                    this.refs['changePasswordForm'].reset();
                    /* setTimeout(() => {
                        this.setState({ successMsg: '' })
                         navigate("/userprofile")
                    }, 1500); */
                } 
                hideButtonLoader();
          });

        
    }


    render() {
        // const { canSubmit } = this.state;
        const { canSubmit, successMsg } = this.state;
        const { buttonLoader } = this.props;
        return (
            <Layout layoutType="profileHeader">
                <SEO title="Servicecontract.com - Contact" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                {/* --------------banner--------- */}

                <div className={global.dealertoken?"profilecontainer grayish":"profilecontainer"}>
                    <div className="container">
                        <div className="profile-content">
                        {/*    <LeftProfile /> */}
                            <div className="right-side">
                                <Formsy ref="changePasswordForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                                    <ErrrorHandel formName={'changePasswordForm'} self={this} />
                                    <div className="form">
                                        <div className="updateaccount-container">
                                            <h1>Change Password <img onClick={() => navigate("/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))} className="userclose" src={closeChat} alt="" /></h1>
                                            <span className="change-password">{successMsg}</span>
                                        </div>
                                        <div className="form-inline">
                                            <div className="left left-side-setting">
                                                <div className="form-label-group error form-part">
                                                    <MyInput
                                                        name="oPassword"
                                                        id="oPassword"
                                                        type="password"
                                                        placeholder="Old Password"
                                                        value={''}
                                                        className="form-control"
                                                        isLabelRequired={true}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-inline">
                                            <div className="left left-side-setting">
                                                <div className="form-label-group error form-part">
                                                    <MyInput
                                                        name="password"
                                                        id="password"
                                                        type="password"
                                                        placeholder="New Password"
                                                        value={''}
                                                        className="form-control"
                                                        isLabelRequired={true}
                                                        validations={{
                                                            minLength: 8
                                                        }}
                                                        validationError="Password must be 8 characters minimum."
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-inline">
                                            <div className="left left-side-setting">
                                                <div className="form-label-group error form-part">
                                                    <MyInput
                                                        name="cPassword"
                                                        id="cPassword"
                                                        type="password"
                                                        placeholder="Confirm Password"
                                                        value={''}
                                                        className="form-control"
                                                        isLabelRequired={true}
                                                        validations="equalsField:password"
                                                        validationError="Password and password confimation do not match."
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-container change-pass-container-btn">
                                        <button className={!canSubmit ? 'btn disabled change-pass-btn' : 'btn change-pass-btn'} disabled={!canSubmit} type="submit">
                                            <ButtonLoader isLoading={buttonLoader} label={'Submit'} />
                                        </button>
                                        <Link className="btn change-pass-btn outline" to={"/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:'')}>Back</Link>
                                    </div>
                                </Formsy>
                            </div>

                        </div>
                    </div>
                </div>

                <section className="copyright-container last-text">
                    <div className="container">
                        <div className="copyright">
                            <small>Copyright servicecontract.com © {new Date().getFullYear()}</small>
                        </div>
                    </div>

                </section>

            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        pageLoader: state.primary.pageLoader,
        buttonLoader: state.primary.buttonLoader,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        hideButtonLoader: () => dispatch(actionCreators.hideButtonLoader())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditPage);

